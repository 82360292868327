import { DOM, WebPageController } from '@sfg-cm/cm-api-dom-web';

const visible = 'display: inline; visibility: visible';
const hidden = 'display: none, visibility: hidden';

export class RequirementsController extends WebPageController {
    private openedImage: string;
    private closedImage: string;
    private appBaseImageURL: string;


    onLoad() {
        this.appBaseImageURL = this.getAttribute('data-scm-images');
        this.openedImage = this.appBaseImageURL + 'open.png';
        this.closedImage = this.appBaseImageURL + 'closed.png';
        DOM.onClick('#cmc_req_expandAll', () => this.expandAll('.cmc_req_ownerName', '.cmc_req_groups'));
        DOM.onClick('#cmc_req_closeAll', () => this.closeAll('.cmc_req_ownerName', '.cmc_req_groups'));
        this.handleMouseEvents('.cmc_req_ownerName', '.cmc_req_groups');
        this.handleMouseEvents('.cmc_req_groupName', '.cmc_req_details');
    }

    changeImageState(node: HTMLElement, image: string) {
        for (const element of DOM.children(node, DOM.isTag('img'))) {
            element.setAttribute('src', image);
        }
    }


    toggleGroups(ownerClass: string, groupClass: string, styles: string, image: string) {
        DOM.queryForEach(ownerClass, owner => {
            const siblingsList = DOM.siblings(owner as HTMLElement, DOM.hasClass(groupClass));
            if (siblingsList.length > 0) {
                siblingsList[0].setAttribute('style', styles);
            }
            this.changeImageState(owner as HTMLElement, image);
        });
    }

    toggleNode(node: HTMLElement, cssClass: string) {
        const siblingsList = DOM.siblings(node, DOM.hasClass(cssClass));
        const block = siblingsList.length > 0 ? siblingsList[0] : undefined;
        if (block) {
            if (block.style.display !== 'inline') {
                block.setAttribute('style', visible);
                this.changeImageState(node, this.openedImage);
            } else {
                block.setAttribute('style', hidden);
                this.changeImageState(node, this.closedImage);
            }
        }
    }

    expandAll(ownerClass: string, groupClass: string) {
        return this.toggleGroups(ownerClass, groupClass, visible, this.openedImage);
    }

    closeAll(ownerClass: string, groupClass: string) {
        return this.toggleGroups(ownerClass, groupClass, hidden, this.closedImage);
    }

    handleMouseEvents(parentClass: string, targetClass: string) {
        DOM.queryForEach(parentClass, element => {
            element.addEventListener('mouseenter', event => {
                const target = event.target as HTMLElement;
                target.style['text-decoration'] = 'underline';
            });
        });
        DOM.queryForEach(parentClass, element => {
            element.addEventListener('mouseleave', event => {
                const target = event.target as HTMLElement;
                target.style['text-decoration'] = 'none';
            });
        });
        DOM.queryForEach(parentClass, element => {
            element.addEventListener('click', event => {
                const target = event.currentTarget as HTMLElement;
                this.toggleNode(target, targetClass);
                target.style['text-decoration'] = 'none';
            });
        });
    }
}
