import { DOM, WebPageController } from '@sfg-cm/cm-api-dom-web';

export class PagerController extends WebPageController {
    pages: NodeListOf<Element>;

    onLoad() {
        this.pages = DOM.query('.pagerPage');
        for (let pageNo = 0; pageNo < this.pages.length; pageNo++) {
            const page = this.pages[pageNo] as HTMLElement;
            const first = DOM.query('a.pagerFirst', page)[0] as HTMLAnchorElement;
            if (first) {
                first.onclick = () => this.showPage(0);
            }
            const prev = DOM.query('a.pagerPrev', page)[0] as HTMLAnchorElement;
            if (prev) {
                prev.onclick = () => this.showPage(pageNo - 1);
            }
            const next = DOM.query('a.pagerNext', page)[0] as HTMLAnchorElement;
            if (next) {
                next.onclick = () => this.showPage(pageNo + 1);
            }
        }
        this.autoFocus();
    }

    showPage(pageNo: number) {
        for (let i = 0; i < this.pages.length; i++) {
            const page = this.pages[i] as HTMLElement;
            if (i === pageNo) {
                DOM.replaceClass(page, 'pagerHide', 'pagerShow');
            } else {
                DOM.replaceClass(page, 'pagerShow', 'pagerHide');
            }
        }
    }

}
