import { DOM, Collections, FocusController, HtmlBuilder, HttpRequest, HttpResult } from '@sfg-cm/cm-api-dom-web';

export type AgentSearchResult = {
	agentKey: number;
	agentNumber: string;
	fullName: string;
	firstName: string;
	lastName: string;
	emailAddress: string;
	status: string;
};

export abstract class AgentSearchController extends FocusController {
    protected request: HttpRequest = new HttpRequest();
    protected searchText = DOM.inputById('userSearchText');
    protected searchFrame = DOM.byId('userSearchFrame');
    protected searchContainer = DOM.byId('userSearch');

    onLoad() {
        super.onLoad();

        DOM.onClick('#userSearchBtn', () => {
            this.request.get(this.getQueryUrl(), (result) => {
                if (result.success) {
                    this.displaySearchResults(this.searchContainer, result);
                    this.searchFrame.classList.remove('d-none');
                    this.searchLoaded();
                } else {
                    console.error(result);
                }
            });
        });

        DOM.onClick('#userSearchCloseBtn', () => {
            DOM.byId('userSearchFrame').classList.add('d-none');
            this.searchText.focus();
        });
    }

    abstract getQueryUrl(): string;

    abstract searchLoaded(): void;

    displaySearchResults(parent: HTMLElement, result: HttpResult) {
        if (parent) {
            DOM.removeChildren(parent);
        }
        if (result.success) {
            const searchResults = result.data.agentSearchResults as AgentSearchResult[];
            if (Collections.isNotEmpty(searchResults)) {
                this.createSearchResultsTable(parent, searchResults);
            } else {
                this.createAlert(parent, 'No matches were found.');
            }
        } else {
            this.createAlert(parent, `Search failed: ${result.message}`);
        }
    }

    createAlert(parent: HTMLElement, message: string, alertType = 'alert-danger') {
        const html =
            new HtmlBuilder('div').classes('alert', alertType).text(message);
        html.appendTo(parent);
    }

    createSearchResultsTable(parent: HTMLElement, searchResults: AgentSearchResult[]) {
        const html =
            new HtmlBuilder('table').classes('table', 'table-striped', 'table-sm', 'table-company', 'table-bordered')
                .push().thead().classes('thead-company').tr().th('Agent No.').classes('text-center').th('Full Name').th('Last Name').th('First Name').th('Email')
                .pop().tbody().classes('tbody-company');
        for (const hit of searchResults) {
            html.push()
                .tr().classes('clickable').click(() => this.selectResult(hit))
                .td().text(hit.agentNumber).classes('hit', 'text-center').parent().td(hit.fullName).td(hit.lastName).td(hit.firstName).td(hit.emailAddress)
                .pop();
        }
        html.appendTo(parent);
    }

    abstract selectResult(hit: AgentSearchResult);
 
 }