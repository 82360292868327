import { DOM, FocusController } from '@sfg-cm/cm-api-dom-web';

export class PolicyInfoController extends FocusController {
    lastName = DOM.inputById('lastName');
    firstName = DOM.inputById('firstName');
    policyNumber = DOM.inputById('policyNumber');
    submitBtn = DOM.buttonById('submit');

    onLoad() {
        super.onLoad();
        DOM.onClick('#reset', () => {
            this.lastName.value = '';
            this.firstName.value = '';
            this.policyNumber.value = '';
            this.lastName.focus();
        });
        DOM.onClick('#search', () => {
            DOM.inputById('action').value = 'Search';
            this.submitBtn.click();
        });
        DOM.queryForEach('.searchResults a', a => {
            (a as HTMLAnchorElement).onclick = () => {
                this.policyNumber.value = a.title;
                this.submitBtn.click();
            };
        });
    }

}
