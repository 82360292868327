import '../scss/styles.scss';

import { DOM, ControllerManager, FocusController, HttpRequest, CsrfToken, Support } from '@sfg-cm/cm-api-dom-web';
import { FormSearchController, FormSearchEmailController } from './form-search-controller';
import { RequirementsController } from './requirements-controller';
import { AgentTrainingController } from './agent-training-controller';
import { InboxListController, InboxViewController, InboxComposeController } from './inbox-controller';
import { CompositeOrderController } from './composite-order-controller';
import { PagerController } from './pager-controller';
import { JournalSearchController } from './journal-search-controller';
import { ImpersonationSearchController } from './impersonation-search-controller';
import { ReportsController } from './reports-controller';
import { PolicyInfoController } from './policyinfo-controller';
import { SelectPoliciesController } from './select-policies-controller';
import { SelectGroupController } from './select-group-controller';

document.addEventListener('DOMContentLoaded', () => {

    DOM.onClick('#logoutUrl', () => {
        DOM.formById('logoutForm').submit();
    });
    
    Support.registerCookieNotice('cookieNotice', 'acknowledge');

});

const manager = new ControllerManager()
    .register('login', (e) => new FocusController(e))
    .register('reset', (e) => new FocusController(e))
    .register('lookup', (e) => new FocusController(e))
    .register('composite-order', (e) => new CompositeOrderController(e))
    .register('formsearch-email', (e) => new FormSearchEmailController(e))
    .register('formsearch', (e) => new FormSearchController(e))
    .register('requirements', (e) => new RequirementsController(e))
    .register('agent-training', (e) => new AgentTrainingController(e))
    .register('inbox-list', (e) => new InboxListController(e))
    .register('inbox-view', (e) => new InboxViewController(e))
    .register('inbox-compose', (e) => new InboxComposeController(e))
    .register('search-results', (e) => new PagerController(e))
    .register('user-password', (e) => new FocusController(e))
    .register('user-preferences', (e) => new FocusController(e))
    .register('journal-search', (e) => new JournalSearchController(e))
    .register('user-search', (e) => new ImpersonationSearchController(e))
    .register('reports', (e) => new ReportsController(e))
    .register('documents', (e) => new ReportsController(e))
    .register('policyinfo', (e) => new PolicyInfoController(e))
    .register('select-group', (e) => new SelectGroupController(e))
    .register('select-policies', (e) => new SelectPoliciesController(e));
    


