
import { DOM, WebPageController } from '@sfg-cm/cm-api-dom-web';

export class CompositeOrderController extends WebPageController {

    onLoad() {
        DOM.focus('#groupNumber');
        DOM.onClick('#reset', () => {
            DOM.setValue('#groupNumber', '');
            DOM.setValue('#subGroup', '');
            DOM.addClasses('#orderResults', 'd-none');
            DOM.focus('#groupNumber');
        });
    }
}
