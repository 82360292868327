import { DOM, WebPageController } from '@sfg-cm/cm-api-dom-web';

interface ClickArea extends HTMLElement {
    isOpen: boolean;
    openedImg: string;
    closedImg: string;
    contentNode: HTMLElement;
    imgNode: HTMLImageElement;
}
export class FormSearchController extends WebPageController {
    private formNumKey: HTMLSelectElement;
    private formNameKey: HTMLSelectElement;
    private formTypeKey: HTMLSelectElement;
    private formNumState: HTMLSelectElement;
    private formSearch: HTMLFormElement;

    onLoad() {
        this.formNumKey = DOM.selectById('formNumKey');
        this.formNameKey = DOM.selectById('formNameKey');
        this.formTypeKey = DOM.selectById('formTypeKey');
        this.formNumState = DOM.selectById('formNumState');
        this.formSearch = DOM.formById('formSearchForm');
        this.formNumKey.onchange = () => {
            this.handleFormChange(this.formNumKey);
        };
        this.formNameKey.onchange = () => {
            this.handleFormChange(this.formNameKey);
        };
        DOM.buttonById('formNumSearchSubmit').onclick = () => {
            this.submitFormNumSearch();
        };
        DOM.buttonById('specificSaleSearchSubmit').onclick = () => {
            this.submitSpecificSaleSearch();
        };
        this.initSectionHandler(document.getElementById('specifcSalesSearchBtn'),
            document.getElementById('specifcSaleSearchFrame'));
        this.initSectionHandler(document.getElementById('formsSearchBtn'),
            document.getElementById('formsSearchFrame'));
    }

    initSectionHandler(clickArea: HTMLElement, contentNode: HTMLElement) {
        const imgNode = DOM.query('img', clickArea)[0] as HTMLImageElement;
        this.setImageToggle(clickArea, imgNode, contentNode, '/images/form-search-closed.png', '/images/form-search-opened.png', true);
    }

    findAndSelect(options: HTMLOptionsCollection, value: string) {
        for (let i = 0; i < options.length; i++) {
            if (value === options[i].value) {
                options.selectedIndex = i;
                return;
            }
        }
    }

    handleFormChange(select: HTMLSelectElement) {
        if (select === this.formNumKey) {
            this.findAndSelect(this.formNameKey.options, select.options[select.selectedIndex].value);
        }
        if (select === this.formNameKey) {
            this.findAndSelect(this.formNumKey.options, select.options[select.selectedIndex].value);
        }
        if (select.selectedIndex !== 0) {
            this.formTypeKey.selectedIndex = 0;
            this.formTypeKey.disabled = true;
            this.formTypeKey.style.backgroundColor = '#EFEFEF';
        } else {
            this.formTypeKey.disabled = false;
            this.formTypeKey.style.backgroundColor = '#FFF';
        }
    }

    checkRequired(select: HTMLSelectElement, description: string) {
        if (select) {
            if ((!select.disabled) && (select.selectedIndex === 0)) {
                alert('Please enter a ' + description + '.');
                select.focus();
                return false;
            }
        }
        return true;
    }


    submitFormNumSearch() {

        if (!this.checkRequired(this.formNumState, 'state')) {
            return;
        }

        this.formSearch.action = './formsearch?action=formNumber#results';
        this.formSearch.submit();
    }

    submitSpecificSaleSearch() {
        if (!this.checkRequired(DOM.selectById('underwritingType'), 'underwriting type')) {
            return;
        }

        if (!this.checkRequired(DOM.selectById('specificSaleState'), 'state')) {
            return;
        }

        this.formSearch.action = './formsearch?action=specificSale#results';
        this.formSearch.submit();
    }

    setImageToggle(element: HTMLElement, imgNode: HTMLImageElement, contentNode: HTMLElement,
                   openedImg: string, closedImg: string, isOpen: boolean) {
        const clickArea = element as ClickArea;
        clickArea.isOpen = isOpen;
        clickArea.openedImg = openedImg;
        clickArea.closedImg = closedImg;
        clickArea.contentNode = contentNode;
        clickArea.imgNode = imgNode;
        clickArea.onclick = () => {
            if (clickArea.isOpen) {
                clickArea.isOpen = false;
                clickArea.contentNode.style.display = 'none';
                clickArea.contentNode.style.visibility = 'hidden';
                clickArea.imgNode.src = clickArea.closedImg;
            } else {
                clickArea.isOpen = true;
                clickArea.contentNode.style.display = 'block';
                clickArea.contentNode.style.visibility = 'visible';
                clickArea.imgNode.src = clickArea.openedImg;
            }
        };
    }

}

export class FormSearchEmailController extends WebPageController {

    onLoad() {
        DOM.focus('#toAddress');
        DOM.onClick('#cancelBtn', () => {
            window.location.href = './formsearch';
        });
    }
}
