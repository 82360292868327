import { AgentSearchController, AgentSearchResult } from './agent-search-controller';
import { DOM } from '@sfg-cm/cm-api-dom-web';

export class ReportsController extends AgentSearchController {
    reportList = DOM.byId('reportList');


    onLoad() {
        super.onLoad();
    }

    selectResult(hit: AgentSearchResult) {
        window.location.replace(`${window.location.pathname}?action=list&agentNumber=${hit.agentNumber}`);
    }

    getQueryUrl(): string {
        return `?action=search&query=${this.searchText.value}`;
    }

    searchLoaded() {
        DOM.removeChildren(this.reportList);
    }
}
