import { DOM, WebPageController } from '@sfg-cm/cm-api-dom-web';

class ConfirmPolicy {
    constructor(public policyNumber: string, public selected: boolean) {
    }
}

class ConfirmSelect {
    constructor(private select: HTMLSelectElement) {

    }

    public removeAll() {
        if (this.select) {
            while (this.select.length > 0) {
                this.select.remove(0);
            }
        }
    }

    public add(policy: ConfirmPolicy) {
        if (this.select && policy && policy.policyNumber) {
            const option = document.createElement("option", null);
            option.text = policy.policyNumber;
            option.value = policy.policyNumber;
            this.select.add(option);
        }
    }

    public disabled(flag: boolean) {
        if (this.select) {
            this.select.disabled = flag;
        }
    }

    public getSelectedPolicyNumbers(): string[] {
        const policies: string[] = [];
        if (this.select) {
            for (let i = 0; i < this.select.length; i++) {
                const option = this.select.item(i) as HTMLOptionElement;
                if (option.selected) {
                    policies.push(option.value);
                }
            }
        }
        return policies;
    }

    getIndex() {
        return this.select.selectedIndex;
    }

    focus(index?: number) {
        this.select.focus();
        if (isNaN(index)) {
            return;
        }
        if ((index >= 0) && (index <= this.select.length)) {
            this.select.selectedIndex = (index < this.select.length) ? index : index - 1;
        }
    }
}

export class SelectPoliciesController extends WebPageController {

    policyList: ConfirmPolicy[] = [];
    availableList: ConfirmSelect;
    selectedList: ConfirmSelect;
    includeAll: boolean = true;
    entireGroupCheckbox: HTMLInputElement;
    submitBtn: HTMLButtonElement;
    selectBtn: HTMLButtonElement;
    selectAllBtn: HTMLButtonElement;
    unselectBtn: HTMLButtonElement;
    unselectAllBtn: HTMLButtonElement;

    onLoad() {
        console.log('select-policies');
        this.availableList = new ConfirmSelect(DOM.selectById('availableList'));
        this.selectedList = new ConfirmSelect(DOM.selectById('selectedList'));
        this.entireGroupCheckbox = DOM.inputById('entireGroup');
        this.entireGroupCheckbox.onclick = () => this.toggleEntireGroup();
        this.submitBtn = DOM.buttonById('submitBtn');
        this.submitBtn.onclick = () => this.submit();
        this.selectBtn = DOM.buttonById('selectBtn');
        this.selectBtn.onclick = () => {
            const index = this.availableList.getIndex();
            this.setPolicyListSelected(true, this.availableList.getSelectedPolicyNumbers());
            this.availableList.focus(index);
        }
        this.selectAllBtn = DOM.buttonById('selectAllBtn');
        this.selectAllBtn.onclick = () => this.setPolicyListSelected(true);
        this.unselectBtn = DOM.buttonById('unselectBtn');
        this.unselectBtn.onclick = () => {
            const index = this.selectedList.getIndex();
            this.setPolicyListSelected(false, this.selectedList.getSelectedPolicyNumbers());
            this.selectedList.focus(index);
        }
        this.unselectAllBtn = DOM.buttonById('unselectAllBtn');
        this.unselectAllBtn.onclick = () => this.setPolicyListSelected(false);
        DOM.formById('selectForm').onsubmit = () => this.submit();
        const policies = DOM.inputById('policies');
        if (policies) {
            for (const polNo of policies.value.split(',')) {
                const policy = new ConfirmPolicy(polNo, false);
                this.policyList.push(policy);
            }
        }
        this.update();
        this.autoFocus();
    }

    public toggleEntireGroup() {
        this.includeAll = !this.includeAll;
        this.update();
    }

    public update() {
        this.availableList.removeAll();
        this.selectedList.removeAll();
        for (const policy of this.policyList) {
            if (policy.selected) {
                this.selectedList.add(policy);
            } else {
                this.availableList.add(policy);
            }
        }
        this.entireGroupCheckbox.checked = this.includeAll;
        this.availableList.disabled(this.includeAll);
        this.selectedList.disabled(this.includeAll);
        this.selectBtn.disabled = this.includeAll;
        this.selectAllBtn.disabled = this.includeAll;
        this.unselectBtn.disabled = this.includeAll;
        this.unselectAllBtn.disabled = this.includeAll;
        this.submitBtn.disabled = !this.canSubmit();
    }

    public setPolicyListSelected(flag: boolean, policyNumbers?: string[]) {
        for (const policy of this.policyList) {
            if ((!policyNumbers) || (policyNumbers.indexOf(policy.policyNumber) >= 0)) {
                policy.selected = flag;
            }
        }
        this.update();
    }

    public canSubmit(): boolean {
        if (this.policyList.length < 1) {
            return false;
        }
        if (this.includeAll) {
            return true;
        }
        let count = 0;
        for (const policy of this.policyList) {
            if (policy.selected) {
                count++;
            }
        }
        return (count > 0);
    }

    public submit() {
        let csv = '';
        for (const policy of this.policyList) {
            if ((policy.selected) || (this.includeAll)) {
                csv = csv + ((csv !== '') ? ',' + policy.policyNumber : policy.policyNumber);
            }
        }
        DOM.setValue('#selectedPolicies', csv)
    }
}


