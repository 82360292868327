import { DOM, WebPageController } from '@sfg-cm/cm-api-dom-web';
import { UserSearchController, UserSearchResult } from './user-search-controller';

export class InboxListController extends WebPageController {

    onLoad() {
        DOM.onClick('#selectAll', (event) => {
            const value = (event.target as HTMLInputElement).checked;
            DOM.queryForEach('.tableDataRow input[type=checkbox]', box => {
                (box as HTMLInputElement).checked = value;
            });
        });
        DOM.onClick('#composeBtn', () => window.location.href = './inbox?action=compose');
        DOM.onClick('#delBtn', () => {
            const form = DOM.formById('listForm');
            if (form) {
                let anyChecked = false;
                DOM.queryForEach('#messageList input[type=checkbox]', element => {
                    const checkbox = element as HTMLInputElement;
                    if (checkbox.checked) {
                        anyChecked = true;
                    }
                });
                if (!anyChecked) {
                    return false;
                }
                if (!confirm('Are you sure you want to delete all selected messages?\nThis action cannot be undone.')) {
                    return false;
                }
                form.action = './inbox?action=deleteList';
                form.submit();
            }
        });
    }

}

export class InboxViewController extends WebPageController {

    onLoad() {
        DOM.onClick('#retBtn', () => window.location.href = './inbox?action=list');
        DOM.onClick('#delBtn', () => DOM.formById('delForm')?.submit());
    }

}

export class InboxComposeController extends UserSearchController {
    private attachmentList: HTMLSelectElement;
    private removeBtn: HTMLButtonElement;
    private toUserId: HTMLInputElement;
    private toLabel: HTMLInputElement;
    private toName: HTMLInputElement;
    private composeForm: HTMLFormElement;
    private csrfTokenName: string;
    private csrfTokenValue: string;

    onLoad() {
        super.onLoad();
        this.csrfTokenName = this.getAttribute('data-scm-csrf-token-name');
        this.csrfTokenValue = this.getAttribute('data-scm-csrf-token-value');
        this.toUserId = DOM.inputById('toUserId');
        this.toLabel = DOM.inputById('toLabel');
        this.toName = DOM.inputById('toName');
        this.removeBtn = DOM.buttonById('removeBtn');
        this.attachmentList = DOM.selectById('attachmentList');
        this.composeForm = DOM.formById('composeForm');


        if (this.attachmentList.length < 2) {
            this.removeBtn.disabled = true;
            this.removeBtn.classList.remove('button');
            this.removeBtn.classList.add('buttonDisabled');
        }

        DOM.onClick('#sendBtn', () => {
            if (this.isRequired() && DOM.isBlank(DOM.inputById('toUserId'))) {
                alert('Please select a recipient.');
                return false;
            }

            this.composeForm.action = `./inbox?action=send&${this.csrfTokenName}=${this.csrfTokenValue}`;
            this.composeForm.submit();
        });

        DOM.onClick('#cancelBtn',
            () => window.location.href = `./inbox?action=cancel&id=${DOM.inputById('messageId')?.value}`);

        DOM.onChange('#attachment', () => {
            this.composeForm.action = `./inbox?action=add&${this.csrfTokenName}=${this.csrfTokenValue}`;
            this.composeForm.submit();
        });

        DOM.onClick('#removeBtn', () => {
            this.composeForm.action = `./inbox?action=remove&${this.csrfTokenName}=${this.csrfTokenValue}`;
            this.composeForm.submit();
        });

    }

    getQueryUrl() {
        return `./inbox?action=search&query=${this.searchText.value}`;
    }

    selectResult(hit: UserSearchResult) {
        this.toLabel.textContent = hit.fullName;
        this.toName.value = hit.fullName;
        this.toUserId.value = hit.userId;
        this.searchFrame.classList.add('d-none');
        this.searchText.focus();
    }

    searchLoaded() { }
    
    isRequired(): boolean {
		const value = DOM.inputById('toRequired')?.value;
		return (value) && (value === 'true');
	}

}
