import { DOM, FocusController } from '@sfg-cm/cm-api-dom-web';

export class ImpersonationSearchController extends FocusController {

    onLoad() {
        DOM.onClick('#reset', () => {
            DOM.inputById('lastName').value = '';
            DOM.inputById('firstName').value = '';
            DOM.inputById('emailAddress').value = '';
            DOM.inputById('agentNumber').value = '';
            DOM.inputById('userId').value = '';
            DOM.inputById('userId').focus();
        });
        super.onLoad();
    }
}

