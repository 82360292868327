import { DOM, WebPageController } from '@sfg-cm/cm-api-dom-web';

export class AgentTrainingController extends WebPageController {

    onLoad() {
        DOM.onClick('#viewDocument', () => {
            document.getElementById('documentViewed')?.setAttribute('value', 'true');
            document.getElementById('optAgree')?.removeAttribute('disabled');
            document.getElementById('optDisagree')?.removeAttribute('disabled');
            document.getElementById('btnSubmit')?.removeAttribute('disabled');
        });

    }

}
