import { DOM, FocusController } from '@sfg-cm/cm-api-dom-web';

export class JournalSearchController extends FocusController {

    onLoad() {
        DOM.onClick('#reset', () => {
            DOM.removeElement('#searchResults');
            DOM.inputById('userId').value = '';
            DOM.inputById('agentNumber').value = '';
            DOM.inputById('policyNumber').value = '';
            DOM.inputById('startDateText').value = '';
            DOM.inputById('endDateText').value = '';
            DOM.inputById('userId').focus();
        });
        super.onLoad();
    }
}
